import Dropdown from '../../classes/Dropdown';
import form_style_2_init from './form_style_2/form_style_2';

gravityforms_init();
raw_select_styling();
// dropdown_children_init(document);

function gravityforms_init(){
    if( typeof jQuery == 'undefined' ) return;
    jQuery(document).on('gform_post_render', function(event, form_id, current_page){
        let form = document.getElementById('gform_'+ form_id);
        if( !form ) return;
        // console.log('gform_post_render', form_id)
        dropdown_children_init(form);
        form_style_2_init(form);
    });
}

function dropdown_children_init(container){
    if( !container ) return;
    container.querySelectorAll('select').forEach(dropdown=>{
        new Dropdown(dropdown, {
            animation_open: 'fade-in-bottom-short 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both',
            // trigger gravityforms conditions
            on_select: ()=>{
                jQuery(dropdown).trigger('change');
            }, 
        });
    })
}

function raw_select_styling(){
    document.querySelectorAll('.select_style').forEach(dropdown=>{
        new Dropdown(dropdown, {
            animation_open: 'fade-in-bottom-short 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both',
        });
    })
}