import { debounce, create_div } from 'js/utils';

const activation_point = 500;

const header = {
    el: document.querySelector('.elementor-location-header'),
    state: 'show',
    is_sticky: false,
}

const spacer = {
    el: null,
    init: false,
    ready: false,
    disabled: document.body.classList.contains('header-type2'),
}

const scroll = {
    direction: 'down',
    last_y: 0,
}

function init(){

    if( !header.el ) return;

    document.addEventListener('scroll', on_scroll, { passive: true });

    if( !spacer.disabled ) {
        window.addEventListener('resize', debounce(()=>{
            spacer_init(true);
        }, 100));
    }
}
setTimeout(init, 50);

function on_scroll(){
    scroll.direction = window.scrollY > scroll.last_y ? 'down' : 'up';
    scroll.last_y = window.scrollY;

    if( scroll.direction === 'down' ) {
        activate_sticky();
        hide();
    }
    else {
        deactivate_sticky();
        show();
    }
}

function spacer_init( force_update = false ){

    if( spacer.disabled ) return;

    if( spacer.init && !force_update ) return;
    spacer.init = true;

    if( !spacer.el ) {
        spacer.el = create_div('site_header_spacer');
        header.el.after(spacer.el);
        spacer.el.append(header.el);
    }
    
    spacer.el.style.minHeight = header.el.offsetHeight + 'px';

    setTimeout(()=>{ spacer.ready = true; }, 100);
}

function activate_sticky(){

    if( header.is_sticky ) return;
    if( window.scrollY < activation_point ) return;

    // on_activate();

    spacer_init();

    header.el.style.transform = 'translateY(-100%)';
    header.el.style.display = 'none';
    header.el.style.position = 'fixed';
    header.el.style.zIndex = 100;
    
    setTimeout(()=>{
        header.el.style.display = '';
    }, 10);

    document.body.classList.add('header_sticky_active');

    header.is_sticky = true;
}

function deactivate_sticky(){

    if( window.scrollY > 0 ) return;

    header.el.style.position = '';
    header.el.style.transform = '';
    header.el.style.zIndex = '';

    document.body.classList.remove('header_sticky_active');
    header.is_sticky = false;
}

function show(){
    if( header.state === 'show' ) return;
    if( header.state !== 'hide' ) return;
    if( !header.is_sticky ) return;
    if( !spacer.disabled && !spacer.ready ) return;
    
    header.state = 'show';  
    header.el.style.transform = 'translateY(0)';
}

function hide(){
    if( header.state === 'hide' ) return;
    if( header.state !== 'show' ) return;
    if( !header.is_sticky ) return;
    if( !spacer.disabled && !spacer.ready ) return;
    
    header.state = 'hide';
    header.el.style.transform = 'translateY(-100%)';
}

// let row_1 = header.el.querySelector('.header_right_row_1');

// function on_activate(){
//     // row_1.wrapper.style.height = 0;
// }

// function on_deactivate(){
//     // row_1.wrapper.style.height = row_1.offsetHeight + 'px';
// }

// function wrap(element, class_name){
//     if( !element ) return;
//     let wrapper = create_div(class_name);
//     element.after(wrapper);
//     wrapper.append(element);
//     return wrapper;
// }
