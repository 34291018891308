import { on_ready, is_elementor_edit, hover_intent } from './js/utils';
//import SaveItems from './classes/SaveItems';

// load global styles
import './main.scss';

import 'modules/overviews/team-overview';

import 'components/forms/forms';
import 'components/header/header';
import 'components/carousel/carousel';
import 'components/accordions/accordions';
import 'components/toggle_height';
import 'components/footer/sticky_footer_cta';
import retina from './vendors/retina/retina';

on_ready(()=>{

    if( is_elementor_edit() ) return;
    dynamic_imports();
    
    init_hover_intent();
    init_retina();

    // Wishlist
    // new SaveItems({
    //     key: 'wishlist',
    //     total_count_element: '.wishlist_count',
    //     add_item_button: '.wishlist_add',
    // });

    // Compare
    // new SaveItems({
    //     key: 'compare',
    //     total_count_element: '.compare_count',
    //     add_item_button: '.compare_add',
    //     limit: 3,
    //     limit_message: 'You can only add up to 3 items to compare',
    // });

    setTimeout(()=>check_elements_scrollbar(), 200);

    show_home_legal_enquiry_section();
    
});

function dynamic_imports(){
    // slide menu
    if( document.querySelector('.ff_slide_menu') ) {
        import('components/slide_menu/slide_menu');
    }
    // search popup
    if( document.querySelector('.search_popup') ) {
        import('components/search_popup/search_popup');
    }
}

function init_hover_intent(){
    document.querySelectorAll('.hover_intent').forEach(el=>{
        hover_intent(el);
    });
}

// add class to elements with scrollbar - for extra padding styling
function check_elements_scrollbar(){
    document.querySelectorAll('.check_scrollbar').forEach(element=>{
        if( element.scrollHeight > element.clientHeight ) {
            element.classList.add('with_scrollbar');
        }
    })
}

function init_retina(){
    
    if( typeof retina === 'undefined' ) return;
    if( window.devicePixelRatio < 2) return;

    let items = document.querySelectorAll('.retina');
    if( !items ) return;
    
    items.forEach(item=>{
        if( item.nodeName == 'IMG' ) {
            item.dataset.rjs = 2;
        } else {
            // add to children
            item.querySelectorAll('img').forEach(img=>{
                img.dataset.rjs = 2;
            })
        }
    })
    
    retina();
}

function show_home_legal_enquiry_section() {
    // Log the event for debugging
    console.log('home-legal-enquiry-section triggered');

    // Select the button element
    const button = document.querySelector('.home-legal-enquiry-section-trigger');
    
    // Select the section element
    const section = document.querySelector('.home-legal-enquiry-section');

    // Check if both button and section exist in the DOM
    if(button && section) {
        // Add click event listener to the button
        button.addEventListener('click', function() {
            // Toggle the 'display' style between 'none' and 'block'
            if (section.style.height === '0px' || section.style.height === '') {
                // Expand the section to fit the content
                section.style.height = (section.scrollHeight + 50) + 'px';

            } else {
                // Collapse the section
                section.style.height = '0px';
            }
        });
    }
}