export default class ToggleHeight {

    constructor( options = {} ){
        this.options = options;
        this.trigger = options.trigger;
        this.target = options.target;
        this.animation_duration = options.animation_duration ?? 400;
        this.init();
    }

    init(){

        this.state = this.target.style.display == 'none' ? 'close' : 'open';

        this.container_init();
        
        this.trigger.addEventListener('click', e=>{
            e.preventDefault();
            if( this.state == 'close' ) {
                this.open();
            }
            else if ( this.state == 'open' ) {
                this.close();
            }
        })
    }

    open(){

        if( this.state == 'opening' ) return;
        this.state = 'opening';

        this.trigger.classList.add('open');
        this.target.classList.add('open');
        
        this.target_container.style.height = this.target.offsetHeight + 'px';
        
        setTimeout(()=>{
            this.on_open_complete();
        }, this.animation_duration);
    }

    close(){

        if( this.state == 'closing' ) return;
        this.state = 'closing';

        this.target_container.style.height = this.target.offsetHeight + 'px';

        this.trigger.classList.remove('open');
        this.target.classList.remove('open');
        
        setTimeout(()=>{
            this.target_container.style.height = 0;
            setTimeout(()=>{
                this.on_close_complete();
            }, this.animation_duration);
        }, 10);

    }

    on_open_complete(){
        this.state = 'open';

        // remove set height after animation, so it can support multiple levels
        this.target_container.style.height = '';

        if( typeof this.options.after_open == 'function' ) {
            this.options.after_open();
        }
    }

    on_close_complete(){
        this.state = 'close';
        if( typeof this.options.after_close == 'function' ) {
            this.options.after_close();
        }
    }

    container_init(){
        this.target_container = document.createElement('div');
        this.target_container.classList.add('toggle_con');
        
        this.target_container.style.overflow = 'hidden';

        let transition = 'height '+ this.animation_duration+'ms ease';
        if( typeof this.options.transition !== 'undefined' ) {
            transition = this.options.transition;
        }

        this.target_container.style.transition = transition;

        this.target.after(this.target_container);
        this.target_container.append(this.target);

        if( this.state == 'close' ) {
            this.target_container.style.height = 0;
            this.target.style.display = '';
        }
        else {
            this.trigger.classList.add('open');
            this.target.classList.add('open');
        }
    }

}
