import './team-overview.scss';

console.log('team-overview.js');

import { on_ready } from 'src/js/utils';

on_ready(()=>{
    team_popup_init();
});

function team_popup_init(){
    
    jQuery('.item-team-2').on('click', function(e) {
        e.preventDefault(); 
        var teamId = jQuery(this).data('id');

        // Find matching popup
        var popup = jQuery('#' + teamId);

        // If the popup exists
        if (popup.length) {
            // Move the popup outside Swiper to the body
            jQuery('body').append(popup);

            // Hide any visible popups
            jQuery('.team-popup').hide();

            // Show the specific popup centered
            popup.css({
                display: 'block',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                position: 'fixed', 
                zIndex: 9999 
            }).fadeIn();
        }
    });

    // Close button
    jQuery('.close-popup').on('click', function() {
        jQuery(this).closest('.team-popup').fadeOut(); 
    });

    // Close clicking outside 
    jQuery(document).on('mouseup', function(e) {
        var popup = jQuery(".team-popup:visible");
        if (popup.length && !popup.is(e.target) && popup.has(e.target).length === 0) {
            popup.fadeOut();  
        }
    });

}